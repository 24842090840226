import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="header">
        <img src="varia_red.jpg" className="logo" alt="logo" />
      </header>
      <div className="main">
        <div className="title">Addresse:</div>
        <div className="text">VariaMedia GmbH / Lerchenstraße 28a / 22767 Hamburg / Germany</div>
        <div className="title">Email:</div>
        <div className="text">info_at_variamedia.de</div>
        <div className="title">Legal:</div>
        <div className="text">
          Amtsgericht Tostedt HRB 200533, USt-IdNr.:
          DE214731000, Geschäftsführer: Bastian Zühlke
        </div>
        <div className="title">Disclaimer:</div>
        <div className="text">
          Die VariaMedia Gesellschaft fuer Softwareentwicklung (&quot;VM&quot;), stellt vorliegend Informationen und
          Daten sowie mittels Hyperlinks (Internetverknuepfung) Informationen anderer Websites zur Verfuegung.
          &nbsp;Diese Informationen und Daten dienen allein Informationszwecken, ohne dass sich der Nutzer auf die
          Aktualitaet, Richtigkeit oder Vollstaendigkeit der Informationen berufen oder verlassen kann.
          &nbsp;VM uebernimmt insofern keine Garantie oder Haftung, insbesondere nicht fuer direkte oder indirekte
          Schaeden, die durch die Nutzung der Informationen oder Daten, die auf den VM Websites zu finden sind,
          entstehen. VM uebernimmt insbesondere keine Verantwortung fuer Inhalte oder die Funktionsfaehigkeit,
          Fehlerfreiheit oder Rechtmaessigkeit von Webseiten Dritter, auf die mittels Link von den VM Websites verwiesen
          wird. VM behaelt sich vor, Aenderungen oder Ergaenzungen der  bereitgestellten Informationen oder Daten
          jederzeit ohne Ankuendigung vorzunehmen. Der Inhalt der VM Webseiten ist urheberrechtlich geschuetzt.
          Die Vervielfaeltigung, Aenderung, Verbreitung oder Speicherung von  Informationen oder Daten, insbesondere
          von Texten, Textteilen oder Bildmaterial, bedarf der  vorherigen Zustimmung durch VM.
          Die Nutzung saemtlicher hier aufgefuehrten Informationen oder Daten,  die Anmeldung zu bestimmten Websites
          sowie saemtliches mit den VM Websites zusammenhaengende Tun, Dulden  oder Unterlassen unterliegen
          ausschliesslich deutschem Recht.
        </div>
      </div>
    </div>
  );
}

export default App;
